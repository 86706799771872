<template>
    <b-container>
        <b-modal id="modal-save-collection" modal-class="modal-save-collection" centered hide-footer
            title="Create Collection" ref="refCreateCollectionModal">
            <div class="d-flex">
                <b-form-input v-model="CollectionName" placeholder="Collection name" />
                <b-button class="ml-2" variant="success" @click="addCollection" style="padding: 5px 10px">
                    <feather-icon icon="SaveIcon" size="18" />
                </b-button>
            </div>
        </b-modal>
        <b-modal id="modal-session-app" ok-only ok-variant="warning" ok-title="Login" modal-class="modal-session-app"
            centered title="Expired !" @ok="gotologin()">
            <b-card-text>Session Expired</b-card-text>
        </b-modal>
        <b-modal id="modal-collection" modal-class="modal-collection" centered hide-footer title="Add To Collection"
            ref="refNewCollectionModal">
            <div class="new-collection">
                <div class="d-flex align-items-center justify-content-between m-2">
                    <h3>Add new collection</h3>
                    <b-button class="p-0" variant="success">
                        <feather-icon icon="PlusIcon" @click="popSaveModal" id="newCol" size="30" />
                    </b-button>
                </div>
                <div class="body mt-2 collection-content">
                    <div v-for="item in CollectionItems" :key="item.id"
                        class="d-flex align-items-center justify-content-between m-2">
                        <div><feather-icon icon="FolderIcon" size="20" /></div>
                        <div>{{ item.name }}</div>
                        <div>
                            <input type="radio" class="radio" v-model="selected" :value="item.id"
                                name="radio-collection" />
                        </div>
                    </div>
                </div>
                <div class="div-btn mt-2 d-flex align-items-center justify-content-center"
                    v-if="CollectionItems.length > 0">
                    <b-button variant="success" style="width: 120px" @click="addCollection"><feather-icon
                            icon="SaveIcon" id="newCol" size="18" />
                        save</b-button>
                </div>
            </div>
        </b-modal>
        <b-form id="searchForApplications" @submit.prevent="applicationsSearch()">
            <b-card class="mb-0">
                <div class="border rounded p-1">
                    <b-row cols="12">
                        <b-col sm="12" md="3" class="d-flex align-items-center search-head-title">
                            <svg class="mr-1" style="width: 50px" aria-hidden="true" viewBox="0 0 40 40"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill="none" d="M0,0h40v40H0V0z"></path>
                                <g>
                                    <path
                                        d="M19.7,19.2L4.3,35.3c0,0,0,0,0,0c0.5,1.7,2.1,3,4,3c0.8,0,1.5-0.2,2.1-0.6l0,0l17.4-9.9L19.7,19.2z"
                                        fill="#EA4335"></path>
                                    <path
                                        d="M35.3,16.4L35.3,16.4l-7.5-4.3l-8.4,7.4l8.5,8.3l7.5-4.2c1.3-0.7,2.2-2.1,2.2-3.6C37.5,18.5,36.6,17.1,35.3,16.4z"
                                        fill="#FBBC04"></path>
                                    <path d="M4.3,4.7C4.2,5,4.2,5.4,4.2,5.8v28.5c0,0.4,0,0.7,0.1,1.1l16-15.7L4.3,4.7z"
                                        fill="#4285F4"></path>
                                    <path
                                        d="M19.8,20l8-7.9L10.5,2.3C9.9,1.9,9.1,1.7,8.3,1.7c-1.9,0-3.6,1.3-4,3c0,0,0,0,0,0L19.8,20z"
                                        fill="#34A853"></path>
                                </g>
                            </svg>
                            <h3 class="function-title">Market Search</h3>
                        </b-col>

                        <b-col sm="12" md="4" class="mt-1 mt-md-2 mt-lg-2 mt-xl-2 search-input-dev">
                            <b-form-group>
                                <b-form-input type="text" placeholder="Search for apps & games" id="search-input"
                                    v-model="search" @focus="handleFocus" @blur="handleBlur" />
                                <small ref="searchError" class="text-danger"></small>
                                <ul v-if="suggest && inputFocused && suggestsArray.length > 0"
                                    class="list-group position-absolute w-100" ref="suggestList" style="z-index: 10">
                                    <li v-for="item in suggestsArray" :key="item.term"
                                        class="list-group-item cursor-pointer">
                                        {{ item.term }}
                                    </li>
                                </ul>
                                <div v-if="suggestsArray.length === 0 && !suggest && inputFocused && search.trim() !== ''"
                                    class="no-data-item">
                                    <span>No data available</span>
                                </div>
                            </b-form-group>
                        </b-col>

                        <b-col sm="12" md="5" class="search-input-dev px-md-0 mt-md-2 mt-lg-2 mt-xl-2">
                            <span class="position-relative">
                                <b-spinner v-if="countryOptions.length === 0" class="spinner countries-loader"
                                    variant="primary" size="60" label="Text Centered" />
                                <img v-if="countryOptions.length !== 0" class="img-flag"
                                    :src="`https://flagsapi.com/${selectedCountry.toUpperCase()}/shiny/32.png`">
                                <b-form-select v-b-tooltip.hover title="Countries" class="w-25 mr-1 ctr-lng-selects"
                                    v-model="selectedCountry">
                                    <option v-for="(option, index) in countryOptions" :key="index" :value="option.code">
                                        {{ option.code.toUpperCase() }}
                                    </option>
                                </b-form-select>
                            </span>

                            <b-form-select v-b-tooltip.hover title="Languages" style="width: 23%;"
                                class="mr-1 ctr-lng-selects" v-model="selectedLang" :options="langOptions" />

                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-success"
                                class="circle btn-search mr-1" type="submit" v-b-tooltip.hover title="Search">
                                <feather-icon icon="SearchIcon" size="14" />
                            </b-button>
                            <span v-if="!progress">
                                <b-button
                                    class="con-vs-tooltip feather-icon mt-1 mt-md-0 mt-lg-0 mt-xl-0 btn-search select-none relative mr-1"
                                    variant="outline-info" v-b-tooltip.hover title="show/hide Filter"
                                    @click="toggleFilterCard()">
                                    <feather-icon icon="FilterIcon" size="14" />
                                </b-button>
                                <b-button
                                    class="con-vs-tooltip btn-search mt-1 mt-md-0 mt-lg-0 mt-xl-0 feather-icon select-none relative mr-1"
                                    variant="outline-warning" v-b-tooltip.hover title="show/hide analytics"
                                    @click="toggleAnalyticsCrad()">
                                    <feather-icon icon="EyeIcon" size="14" />
                                </b-button>
                            </span>
                        </b-col>
                    </b-row>
                </div>
            </b-card>
        </b-form>
        <!--Filter-->
        <b-card ref="filterCard" class="hidden my-1">
            <div>
                <div class="d-md-flex align-items-center justify-content-between">
                    <div class="flex-auto">
                        <h5><feather-icon icon="DownloadIcon" size="20" /> Installs</h5>

                        <b-form-group>
                            <b-form-input type="number" placeholder="Min Installs" v-model="minInstalls" />
                        </b-form-group>
                        <b-form-group>
                            <b-form-input type="number" placeholder="Max Installs" v-model="maxInstalls" />
                        </b-form-group>
                    </div>
                    <div class="flex-auto">
                        <h5><feather-icon icon="CalendarIcon" size="20" /> Release</h5>

                        <b-form-group>
                            <b-form-datepicker v-model="minRelease" :max="maxDate" :date-format-options="{
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit',
                            }" placeholder="Min Date"></b-form-datepicker>
                        </b-form-group>

                        <b-form-group>
                            <b-form-datepicker v-model="maxRelease" :max="maxDate" :date-format-options="{
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit',
                            }" placeholder="Max Date"></b-form-datepicker>
                        </b-form-group>
                    </div>
                </div>
                <small class="text-danger" ref="maxInstallsError"></small>

                <small class="text-danger" ref="maxReleaseError"></small>
            </div>

            <div class="d-flex justify-content-center">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-success" class="mr-1 circle"
                    v-b-tooltip.hover title="Filter" @click="filterApps()">
                    Filter &nbsp; <feather-icon icon="FilterIcon" size="14" />
                </b-button>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-info" class="mr-1 circle"
                    type="submit" v-b-tooltip.hover title="Reset" @click="ResetFilters()">
                    Reset &nbsp; <feather-icon icon="RefreshCwIcon" size="14" />
                </b-button>
            </div>
        </b-card>

        <div class="hidden" ref="applicationsRef">
            <div v-if="!progress">
                <b-card v-if="noApps" class="mt-2">
                    <h1 class="text-center" style="font-size: 3rem">
                        <i class="ri-alert-line"></i>
                    </h1>
                    <h1 class="text-center">No Apps were found</h1>
                    <h1 class="text-center">For {{ search }}</h1>
                </b-card>
                <div v-else>
                    <div class="p-0" ref="analyticsCard">
                        <analytics-card v-if="cliked" :from="'app'" :itemsLength="formattedNumber(totalRows)"
                            :data="search" :totalInstall="formattedNumber(totalInstall)"
                            :totalSuggests="formattedNumber(TotalSuggests)" appCountText="App Count"
                            downloadText="Downloads" keywordSuggestsText="Keywords"></analytics-card>
                    </div>

                    <b-card class="mt-1 overflow-hidden table-margin">
                        <b-row>
                            <b-col cols="6" class="mb-1" offset="6">
                                <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm"
                                    label-for="filterInput" class="mb-0 d-flex align-items-center justify-content-end">
                                    <b-input-group size="sm">
                                        <b-form-input id="filterInput" v-model="filter" type="search"
                                            placeholder="Type to Search" />
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">
                                                Clear
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12">
                                <b-table striped hover responsive :per-page="perPage" :current-page="currentPage"
                                    :items="items" :fields="fields" :filter="filter" :filter-included-fields="filterOn"
                                    @filtered="onFiltered">
                                    <template #cell(icon)="data">
                                        <img :src="data.value" alt="" width="45" />
                                    </template>
                                    <template #cell(title)="data">
                                        <a class="p-0 m-0" style="color: inherit" target="_blank" :href="'/myprofile/apps/' + data.item.appId + ',' + selectedCountry + ',' + selectedLang + ',api,0/info'
                                            ">{{ data.value }}</a>
                                    </template>
                                    <template #cell(developer)="data">
                                        <div id="developer">
                                            <router-link :to="{
                                                name: 'market-manager-accounts-search',
                                                query: {
                                                    search: isNaN(data.item.developerId)
                                                        ? data.item.developer.devId
                                                        : data.item.developerId,
                                                },
                                            }" target="_blank">
                                                {{ data.item.developer.devId }}
                                            </router-link>
                                            <div>
                                                <feather-icon v-if="data.item.isFavorite" v-b-tooltip.hover
                                                    title="Remove Favorite" icon="StarIcon" size="20"
                                                    :class="{ active: data.item.isFavorite }"
                                                    class="feather feather-star stroke-current fill-current h-6 w-6"
                                                    @click="removeAccountFromFavorite(data.item)" />
                                                <feather-icon v-if="!data.item.isFavorite" v-b-tooltip.hover
                                                    title="Add Favorite" icon="StarIcon" size="20"
                                                    :class="{ active: data.item.isFavorite }"
                                                    @click="popCollectionModal(data.item)" />
                                            </div>
                                        </div>
                                    </template>
                                    <template #cell(released)="data">
                                        {{
                                            data.value
                                                ? moment(data.value, "YYYY/MM/DD").format(
                                                    "MMM DD, YYYY"
                                                )
                                                : data.value
                                        }}
                                    </template>
                                    <template #cell(dailyInstalls)="data">
                                        <div v-if="IsPro">
                                            <span v-if="data.item.released">
                                                {{
                                                    calculateDailyInstalls(
                                                        data.item.maxInstalls,
                                                        data.item.released
                                                    )
                                                }}
                                            </span>
                                        </div>
                                        <div class="dailyInstalls" v-else>
                                            <feather-icon icon="LockIcon" size="20" />
                                            <b-button style="
                          padding: 1.6px;
                          font-size: 10px;
                          margin-top: 2px;
                          min-width: 77px !important;
                        " variant="outline-secondary" to="/pages/plan-selection">
                                                Upgrade Now
                                            </b-button>
                                        </div>
                                    </template>
                                    <template #cell(playstoreUrl)="data">
                                        <div class="d-flex" style="gap: 5px">
                                            <p>
                                                <a :href="data.value" target="_blank"
                                                    title="Visit on Store"><feather-icon icon="EyeIcon" size="20" /></a>
                                            </p>
                                            <p>
                                                <a :href="'/myprofile/apps/' + data.item.appId + ',' + selectedCountry + ',' + selectedLang + ',api,0/info'
                                                    " target="_blank" title="Show More Info" class="text-warning">
                                                    <feather-icon icon="InfoIcon" size="20" />
                                                </a>
                                            </p>
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group class="mb-0">
                                    <label class="d-inline-block text-sm-left mr-50">Per page</label>
                                    <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                                        class="w-25" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    align="center" size="sm" class="my-0" />
                            </b-col>
                        </b-row>
                    </b-card>
                </div>
            </div>
            <div v-if="progress && !noApps">
                <b-card class="mt-5">
                    <!-- <div class="text-center my-4">
            <b-spinner class="spinner" variant="primary" size="60" label="Text Centered" />
          </div> -->
                    <b-skeleton-table :rows="5" :columns="4"
                        :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
                </b-card>
            </div>
        </div>
    </b-container>
</template>

<script>
import vSelect from "vue-select";
import {
    BRow,
    BCol,
    BModal,
    BCard,
    BFormSelect,
    BTable,
    BInputGroup,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BFormFile,
    BImg,
    BForm,
    BInputGroupAppend,
    BCardText,
    BButtonGroup,
    VBTooltip,
    BPagination,
    BButton,
    VBHover,
    BContainer,
    BFormInvalidFeedback,
    BAspect,
    BSpinner,
    BFormDatepicker,
    BSkeletonTable,
} from "bootstrap-vue";
import AnalyticsCard from "@/components/market-manager/AnalyticsCard.vue";
import axios from "@axios";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import Editor from "@tinymce/tinymce-vue";
import { deleteAccessTokenCookie } from "@/helper/helpers.js";
import { connectApi } from "@/helper/connectApi";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DifficultyScore from "@/components/market-manager/DifficultyScore.vue";
import { getAccessTokenFromCookie } from "@/auth/utils";
import countriesJson from "../../assets/jsons/countries.json"
import langsJson from "../../assets/jsons/langs.json";

export default {
    components: {
        BRow,
        BCol,
        BFormTextarea,
        BCard,
        BContainer,
        BFormInput,
        BSkeletonTable,
        BFormGroup,
        BFormFile,
        BImg,
        BFormSelect,
        BInputGroupAppend,
        BForm,
        BTable,
        BCardText,
        BInputGroup,
        BModal,
        BButtonGroup,
        VBTooltip,
        BPagination,
        BButton,
        vSelect,
        BAspect,
        editor: Editor,
        BFormInvalidFeedback,
        BSpinner,
        BFormDatepicker,
        AnalyticsCard,
        "difficulty-score": DifficultyScore,
    },
    directives: {
        "b-tooltip": VBTooltip,
        "b-hover": VBHover,
        Ripple,
    },
    data() {
        return {
            selectedLang: "fr-FR",
            langOptions: [],
            selectedCountry: "ma",
            countryOptions: [],
            BeAddToFav: null,
            maxDate: new Date(),
            moment,
            search: '',
            applications: [],
            perPage: 100,
            pageOptions: [10, 25, 100],
            totalRows: 1,
            currentPage: 1,
            filter: null,
            items: [],
            progress: true,
            suggest: false,
            suggestsArray: [],
            CollectionName: null,
            CollectionItems: [],

            fields: [
                {
                    key: "icon",
                    label: "",
                },
                {
                    key: "title",
                    label: "Title",
                },
                {
                    key: "scoreText",
                    label: "Score",
                },
                {
                    key: "maxInstalls",
                    label: "Installs",
                    sortable: true,
                },
                {
                    key: "dailyInstalls",
                    label: "daily installs",
                    sortable: true,
                },
                {
                    key: "developer",
                    label: "Account",
                },
                {
                    key: "released",
                    label: "Release date",
                    sortable: true,
                },
                {
                    key: "version",
                    label: "Version",
                },
                {
                    key: "playstoreUrl",
                    label: "",
                },
            ],
            minInstalls: null,
            maxInstalls: null,
            minRelease: null,
            maxRelease: null,
            backUpApps: [],
            inFavorite: [],
            totalInstall: 0,
            TotalSuggests: 0,
            arabicChar: /[\u0600-\u06FF]/,
            characters: [
                "a",
                "b",
                "c",
                "d",
                "e",
                "f",
                "g",
                "h",
                "i",
                "j",
                "k",
                "l",
                "m",
                "n",
                "o",
                "p",
                "q",
                "r",
                "s",
                "t",
                "u",
                "v",
                "w",
                "x",
                "y",
                "z",
                // "0",
                // "1",
                // "2",
                // "3",
                // "4",
                // "5",
                // "6",
                // "7",
                // "8",
                // "9",
            ],
            charactersAr: [
                "أ",
                "ب",
                "ت",
                "ث",
                "ج",
                "ح",
                "خ",
                "د",
                "ذ",
                "ر",
                "ز",
                "س",
                "ش",
                "ص",
                "ض",
                "ط",
                "ظ",
                "ع",
                "غ",
                "ف",
                "ق",
                "ك",
                "ل",
                "م",
                "ن",
                "ه",
                "و",
                "ي",
            ],
            selected: 0,
            token: null,
            server: 1,
            userId: null,
            NotExistsDefault: true,
            cliked: false,
            filterOn: [],
            noApps: false,
            IsPro: null,
            inputFocused: false,
            blurTimeout: null,
        };
    },
    mounted() {
        const data = getAccessTokenFromCookie();
        this.userId = data[2].id;
        this.getCollections();
        this.allFavoriteAccounts();
        this.getCountries();
        if (this.$route.query.search) {
            this.search = this.$route.query.search;
            this.applicationsSearch();
        }
        document.addEventListener("click", (e) => {
            if (e.target.classList.contains("list-group-item")) {
                this.search = e.target.textContent.trim() ? e.target.textContent.trim() : '';
                this.applicationsSearch();
            }
        });

    },
    computed: {
        formattedNumber() {
            return function (number) {
                return number.toLocaleString();
            };
        },
    },
    watch: {
        search(value) {
            if (value.trim()) {
                this.suggest = true;

                this.getSuggests();
            } else {
                this.suggest = false;
            }
        },
        minRelease(v) {
            return v ? v.replace("-", "/") : null;
        },
        maxRelease(v) {
            return v ? v.replace("-", "/") : null;
        },
    },
    methods: {
        handleFocus() {
            clearTimeout(this.blurTimeout);
            this.inputFocused = true;
        },
        handleBlur() {
            this.blurTimeout = setTimeout(() => {
                const suggestList = this.$refs.suggestList;
                if (suggestList && suggestList.contains(document.activeElement)) {
                    return; // Skip blur if focus is on suggest list
                }
                this.inputFocused = false;
            }, 200);
        },
        async getCountries() {
            this.countryOptions = countriesJson.countries
            this.langOptions = langsJson.langs

            //-------set lang based on browser lang
            const langHeader = navigator.language || navigator.userLanguage || navigator.browserLanguage || navigator.systemLanguage;
            const langs = langHeader.split(',');
            const currLang = langs.map((lang) => lang.trim());
            const isLangInArray = this.langOptions.some(country => country.value === currLang[0])
            if (isLangInArray) {
                this.selectedLang = currLang[0];
            } else {
                this.selectedLang = "fr-FR";
            }
        },
        checkUserPlan() {
            axios
                .post("/checkUserSubscription")
                .then(({ data }) => {
                    this.IsPro = true;
                })
                .catch((err) => {
                    this.IsPro = false;
                });
        },
        calculateDailyInstalls(numberInstalls, releaseDate) {
            const releaseDateParts = releaseDate.split("/");
            const releaseDateObj = new Date(
                parseInt(releaseDateParts[0], 10),
                parseInt(releaseDateParts[1], 10) - 1, // Months are zero-based
                parseInt(releaseDateParts[2], 10)
            );

            const currentDate = new Date();
            const timeDiff = Math.abs(currentDate - releaseDateObj);
            const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

            return Math.floor(numberInstalls / daysDiff);
        },
        popSaveModal() {
            this.$root.$emit("bv::show::modal", "modal-save-collection");
        },
        popCollectionModal(item) {
            this.BeAddToFav = item;
            this.$root.$emit("bv::show::modal", "modal-collection");
        },
        showToast(variant, icon, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    text,
                    variant,
                },
            });
        },
        allFavoriteAccounts() {
            axios
                .post("/all-accounts-in-favorite", { userId: this.userId })
                .then(({ data }) => {
                    data.collections.forEach((x) => {
                        this.inFavorite.push(...x.favorite_accounts);
                    });
                });
        },
        addAccountToFavorite(Item, IdCol) {
            const name = Item.developer.devId;
            const id = isNaN(Item.developerId)
                ? Item.developer.devId
                : Item.developerId;
            Item.isFavorite = true;
            const addAllFav = this.items.filter(
                (x) => x.developer.devId === name || x.developerId === id
            );
            addAllFav.map((x, i) => {
                x.isFavorite = true;
            });
            axios
                .post("/add-accounts-to-favorite", {
                    name: name,
                    devId: id,
                    idCol: IdCol,
                })
                .then(({ data }) => {
                    if (data.status === "success") {
                        this.showToast("success", "StarIcon", "Success", data.message);
                        this.allFavoriteAccounts();
                    } else {
                        this.showToast("danger", "StarIcon", "Error", data.message);
                    }
                });
        },
        getCollections() {
            axios
                .post(
                    "/all-collections",

                    { userId: this.userId }
                )
                .then(({ data }) => {
                    const cols = data.data.map((item, index) => {
                        item["isFavorite"] = false;
                        item.index = index + 1;
                        item.released = item.released
                            ? moment(item.released, "MMM DD, YYYY").format("YYYY/MM/DD")
                            : "";

                        return item;
                    });
                    this.CollectionItems = cols;
                    if (!this.CollectionItems.find((x) => x.name === "Default")) {
                        this.CollectionItems.unshift({ id: 0, name: "Default" });
                    } else {
                        this.selected = this.CollectionItems.find(
                            (x) => x.name === "Default"
                        ).id;
                    }
                });
        },
        addCollection() {
            if (this.CollectionName != null || this.selected === 0) {
                axios
                    .post("/add-collection", {
                        name: this.selected === 0 ? "Default" : this.CollectionName,
                        userId: this.userId,
                    })
                    .then(({ data }) => {
                        if (data.status === "success") {
                            this.getCollections();
                            this.allFavoriteAccounts();
                            const id_col = data.collection;
                            this.CollectionName = null;
                            this.addAccountToFavorite(this.BeAddToFav, id_col);
                            this.$refs.refCreateCollectionModal.hide();
                            this.$refs.refNewCollectionModal.hide();
                        } else if (data.status === "warning") {
                            this.showToast("warning", "SaveIcon", "Success", data.message);
                        } else {
                            this.showToast(
                                "danger",
                                "AlertTriangleIcon",
                                "Error",
                                "data.message"
                            );
                        }
                    });
            } else {
                this.addAccountToFavorite(this.BeAddToFav, this.selected);
                this.$refs.refNewCollectionModal.hide();
                this.selected = this.CollectionItems.find(
                    (x) => x.name === "Default"
                ).id;
            }
        },
        removeAccountFromFavorite(Item) {
            const id = isNaN(Item.developerId)
                ? Item.developer.devId
                : Item.developerId;
            Item.isFavorite = false;
            const obj = this.inFavorite.find((x) => x.name === id || x.devID === id);
            const idDeleted = obj.id;
            const RemoveAllFav = this.items.filter((x) => x.developer.devId == id);
            RemoveAllFav.map((x, i) => {
                x.isFavorite = false;
            });
            axios
                .post("/remove-accounts-from-favorite", {
                    id: idDeleted,
                })
                .then(({ data }) => {
                    if (data.status === "success") {
                        this.showToast("success", "StarIcon", "Success", data.message);
                        this.allFavoriteAccounts();
                    } else {
                        this.showToast("danger", "StarIcon", "Error", data.message);
                    }
                });
        },
        applicationsSearch() {
            this.checkUserPlan();

            if (!this.search) {
                this.$refs.searchError.textContent = "The Search Field Is Required!";
                return;
            }

            this.cliked = true;
            this.progress = true;
            this.getSumSuggests();
            this.$refs.searchError.textContent = "";
            this.$refs.applicationsRef.classList.remove("hidden");

            axios.post("/store-inputs-users", {
                from: "application",
                inputText: this.search,
            });

            const data = connectApi(
                `https://scx.clicklab.app/api/apps/?q=${this.search}&country=${this.selectedCountry}&lang=${this.selectedLang}&fullDetail=true`
            );

            data.then((response) => {
                const { status, results } = response.data;

                if (status === "Unauthorized") {
                    deleteAccessTokenCookie();
                    this.$root.$emit("bv::show::modal", "modal-session-app");
                    return [];
                }

                return results || [];
            }).then((results) => {
                this.progress = false;

                if (results.length > 0) {
                    this.noApps = false;
                    this.items = this.applications = this.addIndexToTable(results.filter(element => element !== null));
                    this.averageTotalApp(this.items);
                    this.backUpApps = this.items;
                    this.totalRows = results.length;
                } else {
                    this.noApps = true;
                    this.items = this.applications = [];
                    this.totalRows = 0;
                }
            })
                .catch((error) => {
                    console.error("Error occurred:", error);
                    this.progress = false;
                    this.noApps = true;
                    this.items = this.applications = [];
                    this.totalRows = 0;
                });
        },
        filterApps() {
            this.items = this.backUpApps;
            const filteredApps = this.items.filter((app) => {
                if (this.minInstalls && this.maxInstalls) {
                    if (parseInt(this.minInstalls) < parseInt(this.maxInstalls)) {
                        if (this.minInstalls && app.maxInstalls < this.minInstalls) {
                            this.$refs.maxInstallsError.textContent =
                                "The max installs must be Greater than min installs ";
                            return false;
                        }
                        if (this.maxInstalls && app.maxInstalls > this.maxInstalls) {
                            this.$refs.maxInstallsError.textContent = "";
                            return false;
                        }
                        this.$refs.maxInstallsError.textContent = "";
                    } else {
                        this.$refs.maxInstallsError.textContent =
                            "The max installs must be Greater than min installs ";
                    }
                }
                if (this.minRelease && this.maxRelease) {
                    if (new Date(this.minRelease) < new Date(this.maxRelease)) {
                        if (
                            this.minRelease &&
                            new Date(app.released) < new Date(this.minRelease)
                        ) {
                            return false;
                        }
                        if (
                            this.maxRelease &&
                            new Date(app.released) > new Date(this.maxRelease)
                        ) {
                            return false;
                        }
                        this.$refs.maxReleaseError.textContent = "";
                    } else {
                        this.$refs.maxReleaseError.textContent =
                            "The max release date must be Greater than min release date";
                    }
                }

                return true;
            });

            this.items = filteredApps.flat();
            this.averageTotalApp(this.items);
            this.totalRows = this.items.length;
        },
        addIndexToTable(table) {
            return table.map((item, index) => {
                if (!item) {
                    console.error("Item is null or undefined at index:", index);
                    return null; // Skip null items if necessary
                }

                item.isFavorite = false;

                for (const fav of this.inFavorite) {
                    if (fav.name === item.developer.devId || fav.devId === item.developerId) {
                        item.isFavorite = true;
                        break; // Stop checking once a match is found
                    }
                }

                item.index = index + 1;
                item.released = item.released
                    ? moment(item.released, "MMM DD, YYYY").format("YYYY/MM/DD")
                    : "";

                return item;
            }).filter(item => item !== null); // Filter out any null items if skipped
        },
        getSuggests() {
            const data = connectApi(
                `https://scx.clicklab.app/api/apps/?suggest=${this.search}`
            );
            data.then((response) => {
                const { status } = response.data;
                if (status === "Unauthorized") {
                    deleteAccessTokenCookie();
                    this.$root.$emit("bv::show::modal", "modal-session-app");
                    return;
                }
                const { results } = response.data;
                return results;
            })
                .then((results) => {
                    if (results.length > 0) {
                        this.suggestsArray = results;
                        this.suggest = true;
                    } else {
                        this.suggestsArray = [];
                        this.suggest = false;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.suggestsArray = [];
                    this.suggest = false;
                });
        },
        getSumSuggests() {
            this.TotalSuggests = 0;
            if (this.search.match(this.arabicChar)) {
                this.charactersAr.forEach((char) => {
                    const data = connectApi(
                        `https://scx.clicklab.app/api/apps/?suggest=${this.search.trim()} ${char}`
                    );
                    data
                        .then((response) => {
                            const { status } = response.data;
                            if (status === "Unauthorized") {
                                deleteAccessTokenCookie();
                                this.$root.$emit("bv::show::modal", "modal-session-app");
                                return;
                            }
                            const { results } = response.data;
                            return results;
                        })
                        .then((results) => {
                            if (results.length > 0) {
                                this.TotalSuggests += results.length;
                            }
                        })
                        .catch(console.error);
                });
            } else {
                this.characters.forEach((char) => {
                    const data = connectApi(
                        `https://scx.clicklab.app/api/apps/?suggest=${this.search.trim()} ${char}`
                    );
                    data
                        .then((response) => {
                            const { status } = response.data;
                            if (status === "Unauthorized") {
                                deleteAccessTokenCookie();
                                this.$root.$emit("bv::show::modal", "modal-session-app");
                                return;
                            }
                            const { results } = response.data;
                            return results;
                        })
                        .then((results) => {
                            if (results.length > 0) {
                                this.TotalSuggests += results.length;
                            }
                        })
                        .catch(console.error);
                });
            }
        },
        toggleFilterCard() {
            this.$refs.filterCard.classList.toggle("hidden");
        },
        toggleAnalyticsCrad() {
            this.$refs.analyticsCard.classList.toggle("hidden");
        },
        toglleCollectionModal() {
            this.$refs.collectionModal.classList.toggle("hidden");
        },
        ResetFilters() {
            this.$refs.maxReleaseError.textContent = "";

            this.minInstalls = null;
            this.maxInstalls = null;
            this.minRelease = null;
            this.maxRelease = null;
            this.items = this.backUpApps;
            this.totalRows = this.backUpApps.length;
            this.currentPage = 1;
        },
        showToast(variant, icon, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    text,
                    variant,
                },
            });
        },
        gotologin() {
            this.$router.push({ name: "auth-login" });
        },
        averageTotalApp(apps) {
            this.totalInstall = 0;
            apps.forEach((app) => {
                this.totalInstall += app.maxInstalls;
            });
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
    },
};
</script>

<style lang="scss">
.dailyInstalls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@import "@core/scss/vue/pages/dashboard-ecommerce.scss";

#developer {
    display: flex !important;
    justify-content: space-between;
    gap: 10px;
}

.no-data-item {
    border: 1px solid #9ea3ab5d;
    padding: 10px 17px;
    border-radius: 7px;
    width: calc(100% - 13px);
    position: absolute;
    background-color: #283046;
    z-index: 10px;
}


.active {
    color: #ffd000 !important;
}

.ctr-lng-selects {
    cursor: pointer;
}

.img-flag {
    position: absolute;
    top: -6px;
    right: 45px;
    cursor: pointer;
    pointer-events: none;
}

.collection {
    min-height: 500px;
    border: solid 1px black;
    padding: 20px;
    display: flex;
    z-index: 500;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: -28px;
    width: 60%;
}

#modal-collection___BV_modal_body_ {
    min-height: 500px;
}

#close {
    cursor: pointer;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    background: red;
}

.title {
    position: absolute;
    left: 10px;
    top: 5px;
}

.modal-collection .modal-dialog {
    position: fixed;
    bottom: -95px;
    left: 0;
    right: 0;
    margin: 0;
}

.modal-collection .modal-content {
    height: 70vh;
    border-radius: 0;
}

.radio {
    accent-color: green;
}

.container-app {
    overflow: hidden;
}

.flex-auto {
    flex: auto;
    margin-right: 5px;
}

.collection-content {
    max-height: 330px !important;
    overflow-x: hidden;
}

.search-input-dev {
    padding-left: 0;
}

.btn-search {
    width: 10%;
    padding-left: 0px;
    padding-right: 0px;
}

@media only screen and (max-width: 1397px) {
    .search-input-dev {
        flex: none;
        max-width: none;
        margin-top: 10px !important;

    }

    .search-input-dev {
        padding-left: 16px !important;
    }

    .search-head-title {
        flex: none;
        max-width: none;
    }
}

@media only screen and (max-width: 740px) {

    .btn-search,
    .input-search {
        width: 100% !important;
    }

    .ctr-lng-selects {
        width: 100% !important;
        margin-bottom: 10px;
        margin-left: auto !important;
    }

    .img-flag {
        position: absolute;
        top: -10px;
        right: 45px;
        cursor: pointer;
        pointer-events: none;
    }
}


.position-relative {
    position: relative;
}

.overlay {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    border-top: none;
    display: none;
}

.overlay.show {
    display: block;
}
</style>
